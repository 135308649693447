import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/green-homes-in-san-diego-what-buyers-should-know";

  return (
    <LayoutWrapper
      title="Green Homes in San Diego: What Buyers Should Know"
      desc="Explore the eco-friendly side of San Diego real estate. Discover the key aspects of green homes that buyers should know. Read to learn more!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Green Homes in San Diego: What Buyers Should Know
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By Sarah
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Jan 22, 2024</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Green Homes In San Diego What Buyers Should Know.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Recently, there have been significant shifts in the housing market, with many remote
                workers leaving urban centers for smaller, more affordable markets. However,
                employers are working to bring workers back to urban offices. Even if you have the
                luxury of working remotely, you may be keen to enjoy the many benefits of life in
                San Diego, from fine dining and endless entertainment opportunities to proximity to
                the beach and temperate weather year-round.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As a{" "}
                <a
                  href="https://selbysellssd.com/buy-house-san-diego"
                  target="_Blank"
                  className="text-blue-500"
                >
                  buyer
                </a>{" "}
                looking to purchase a home in the appealing San Diego area, you might be interested
                in properties that offer the opportunity to offset your carbon footprint and reduce
                your monthly expenses. The right real estate purchase could deliver on both scores.
                Here’s what buyers should know about green homes in San Diego before they start
                house hunting.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                The Green Building Incentive Program
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                California has long supported green initiatives, including taxpayer incentives to
                upgrade properties with eco-friendly additions like solar panels and
                energy-efficient windows, doors, appliances, and more. San Diego is no exception,
                thanks to the{" "}
                <a
                  href="https://www.sandiegocounty.gov/pds/greenbuildings.html"
                  target="_Blank"
                  className="text-blue-500"
                >
                  Green Building Incentive Program
                </a>
                .
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                With an eye toward sustainability and energy efficiency, this program promotes green
                building and construction in the county, generating benefits like:
                <ul className="list-disc px-[20px] text-xs pt-[10px]">
                  <li>Conservation of water, energy, and natural resources</li>
                  <li>Reduced costs for electric and water utilities</li>
                  <li>Use of sustainable construction materials and practices</li>
                  <li>Reduced environmental impact of construction</li>
                  <li>Health benefits</li>
                  <li>Economic returns over time</li>
                </ul>
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As a result, it may be easier to find green homes in San Diego than in other cities.
                What eco-friendly amenities are you likely to come across when searching for
                sustainable properties?
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Sustainable Housing Attributes
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Solar panels are practically a given for eco-friendly housing in sunny Southern
                California, but what other amenities might you find when searching for green homes?
                Energy-efficient windows, doors, and insulation offer a great way to regulate
                interior temps and reduce AC and heating demand.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Programmable thermostats also offer opportunities to minimize energy waste, as do
                smart controls for lighting and other electronics in the home. Regarding water
                conservation, you may find options for gray water systems that use recycled water
                for landscaping, along with cisterns for water collection and storage.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Drought-resistant landscaping enjoyed a significant surge during California’s recent
                and extended drought. Finally, sustainable construction may feature a range of
                recycled materials designed to subvert the linear consumer model from production to
                landfill.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Finding Your Eco Dream Home
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                While San Diego may offer more opportunities for sustainable housing than the
                average city, your best bet to find what you’re looking for is with the help of a
                trusted local real estate agency that knows the city inside and out. Whether you’re
                happy with solar panels and drought-resistant landscaping or looking for a certified
                LEED or Passive House property, the right realtor can help you find options that
                meet your every need.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
